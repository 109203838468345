import useAdDashboard from "./hooks/useList";
import "./css/list.scss";
import { formatDate } from "../../../util/dateFormat";

const RegistrationList = () => {
  const hookMember = useAdDashboard();

  return (
    <div id="adddi-light-registration-table">
      <h1>ADDDI LIGHT - 실시간 기기 등록</h1>
      {hookMember.modalIsOpen ? (
        <div id="modal">
          <div>
            <button onClick={() => hookMember.modalStatusChange(false)}>
              X
            </button>
          </div>
          <div>
            <div>
              <span>데이터 연동에 사용될</span>
              <span>범용 매체 식별번호를 입력하세요</span>
              <span>(함체에 기재되어 있음)</span>
            </div>
            <div>
              <span>AL-LA-RK-</span>
              <input
                type="text"
                value={hookMember.inputNumber}
                onChange={(e) => {
                  if (isNaN(Number(e.target.value))) return;
                  if (e.target.value.length > 6) return;
                  hookMember.setModalCheck(false);
                  hookMember.setInputNumber(Number(e.target.value));
                }}
              />
              <button
                onClick={() => {
                  hookMember.checkMediaNumber();
                }}>
                검사
              </button>
              <button
                onClick={() => {
                  hookMember.inspectorApproved(hookMember.modalIdx, "approved");
                }}
                className={hookMember.modalCheck ? "click_o" : "click_x"}>
                등록
              </button>
            </div>
            <div>
              <span>{hookMember.modalText}</span>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {!hookMember.loading ? (
        <div>
          <table>
            <thead>
              <tr>
                <th>등록 요청 시간</th>
                <th>MAC</th>
                <th>IP</th>
                <th>지역</th>
                <th>인스펙터 식별 요청</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {hookMember.data.length === 0 ? (
                <tr>
                  <td colSpan={6}>해당 매체가 없습니다.</td>
                </tr>
              ) : (
                hookMember.data.map((item: any, index: number) => {
                  return (
                    <tr key={index}>
                      <td
                        className="hovertext"
                        data-hover={formatDate(
                          new Date(item.createDate),
                          "yyyy-mm-dd hh:MM:ss"
                        )}>
                        {hookMember.timeToString(
                          item.createDate,
                          hookMember.now
                        )}
                      </td>
                      <td>{item.mac}</td>
                      <td>{item.ip}</td>
                      <td>{item.address}</td>
                      {item.registrationStatus == "등록 대기" ? (
                        <>
                          {item.requestTime ? (
                            <td
                              className="hovertext"
                              data-hover={formatDate(
                                new Date(item.requestTime),
                                "yyyy-mm-dd hh:MM:ss"
                              )}>
                              {hookMember.timeToString(
                                item.requestTime,
                                hookMember.now
                              )}
                            </td>
                          ) : (
                            <td>-</td>
                          )}

                          <td>
                            <button
                              onClick={() => {
                                hookMember.modalStatusChange(true, item);
                              }}>
                              등록
                            </button>
                            <button
                              onClick={() => {
                                hookMember.inspectorApproved(
                                  item.idx,
                                  "refusal"
                                );
                              }}>
                              거부
                            </button>
                          </td>
                        </>
                      ) : (
                        <>
                          <td>{item.registrationStatus}</td>
                          <td></td>
                        </>
                      )}
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <div>
          <p>Loading ...</p>
        </div>
      )}
    </div>
  );
};

export default RegistrationList;
