// src/screens/Demo/useDemo.ts
import { useState } from 'react';

export const useDemo = () => {
  // 성별 상태 관리
  const [gender, setGender] = useState<any[]>([]);
  const [age, setAge] = useState<any[]>([]);
  const [category, setCategory] = useState<string>('');
  const [localTrendResult, setLocalTrendResult] = useState<any[]>([]);
  const [localPopulationResult, setLocalPopulationResult] = useState<any[]>([]);
  const [interestResults, setInterestResults] = useState<any[]>([]);
  const [populationRatio, setPopulationRatio] = useState(0.5);
  const [footTrafficResult, setFootTrafficResult] = useState<any[]>([]);
  const [filteredTrend, setFilteredTrend] = useState<any[]>([]);


  // 다중 선택 처리를 위한 함수 업데이트 예시
  const handleGenderChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValues = Array.from(
      event.target.selectedOptions,
      (option) => option.value,
    );
    setGender(selectedValues); // 다중 선택된 성별을 배열로 저장
  };

  const handleAgeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValues = Array.from(
      event.target.selectedOptions,
      (option) => option.value,
    );
    setAge(selectedValues); // 다중 선택된 연령대를 배열로 저장
  };

  const handleCategoryChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setCategory(event.target.value);
  };

  const handleSearch = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}/local-trend/demo-function?gender=${gender}&age=${age}&category=${category}&populationRatio=${populationRatio}`,
      );

      const data = await response.json();

      setLocalTrendResult(data[0]);
      setLocalPopulationResult(data[1]);
      setInterestResults(data[2]); // 지역추천
      setFootTrafficResult(data[3]);
      setFilteredTrend(data[4]);
    } catch (error) {
      console.error('Error fetching trend data:', error);
    }
  };


  return {
    gender,
    handleGenderChange,
    age,
    handleAgeChange,
    category,
    handleCategoryChange,
    handleSearch,
    localTrendResult,
    localPopulationResult,
    interestResults,
    setGender,
    setAge,
    setCategory,
    populationRatio,
    setPopulationRatio,
    footTrafficResult,
    filteredTrend
  };
};
