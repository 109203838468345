import { useEffect, useState } from "react";

interface hookMember {
  loading: boolean;
  data: any;
  timeToString: (time: string, now: number) => string;
  inspectorApproved: (idx: number, stauts: "approved" | "refusal") => void;
  modalIsOpen: boolean;
  modalIdx: number;
  modalCheck: boolean;
  setModalCheck: (modalCheck: boolean) => void;
  modalStatusChange: (is_open: boolean, obj?: any) => void;
  inputNumber: number;
  setInputNumber: (n: number) => void;
  checkMediaNumber: () => void;
  modalText: string;
  now: number;
}

const useAdList = (): hookMember => {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any[]>([]);
  const [page, setPage] = useState<number>(0);
  const [count, setCount] = useState<number>(0);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [modalIdx, setModalIdx] = useState<number>(-1);
  const [modalCheck, setModalCheck] = useState<boolean>(false);
  const [modalText, setModalText] = useState<string>("");
  const [inputNumber, setInputNumber] = useState<number>(0);
  const [now, setNow] = useState<number>(0);

  const fetchData = async () => {
    // if (!loading) return;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}/adddi-light-registration`
      );
      const jsonData = await response.json();

      if (jsonData.status == 200) {
        setLoading(false);
        setData(jsonData.data.list);
      } else {
        setLoading(false);
        setData([]);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
      setData([]);
    }
    intervalNow();
  };

  const inspectorApproved = async (
    idx: number,
    status: "approved" | "refusal"
  ) => {
    if (status == "approved" && !modalCheck) {
      alert("식별번호 검사 후 등록해주세요.");
      return;
    }
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}/adddi-light-registration/inspectorApproved`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            idx,
            status,
            number: inputNumber,
          }),
        }
      );
      const jsonData = await response.json();

      if (jsonData.status == 200) {
        setModalIsOpen(false);
        fetchData();
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const checkMediaNumber = async () => {
    if (!inputNumber) {
      setModalText("식별번호를 입력해주세요.");
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}/adddi-light-registration/checkMediaNumber?mediaNumber=${inputNumber}`
      );
      const jsonData = await response.json();

      if (jsonData.status == 200) {
        if (jsonData.data) {
          setModalText("");
          setModalCheck(true);
        } else {
          setModalText(jsonData.message);
          setModalCheck(false);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const intervalNow = () => {
    setInterval(() => {
      setNow(new Date().getTime());
    }, 1000);
  };

  const modalStatusChange = (is_open: boolean, obj: any = 0) => {
    setModalIsOpen(is_open);
    setModalCheck(false);
    if (obj === 0) {
      setModalIdx(-1);
    } else {
      setModalIdx(obj.idx);
      setInputNumber(0);
    }
  };

  const timeToString = (time: string, now: number) => {
    if (!now) return "-";
    let diffSecond = (now - new Date(time).getTime()) / 1000;

    if (diffSecond < 60) {
      return Math.floor(diffSecond) + "초 전";
    } else if (diffSecond < 60 * 60) {
      return Math.floor(diffSecond / 60) + "분 전";
    } else if (diffSecond < 60 * 60 * 24) {
      return Math.floor(diffSecond / 60 / 60) + "시간 전";
    } else {
      return Math.floor(diffSecond / 60 / 60 / 24) + "일 전";
    }
  };

  useEffect(() => {
    fetchData();
    setInterval(() => {
      fetchData();
    }, 10000);
  }, []);

  return {
    loading,
    data,
    timeToString,
    inspectorApproved,
    modalIsOpen,
    modalIdx,
    modalCheck,
    setModalCheck,
    modalStatusChange,
    inputNumber,
    setInputNumber,
    checkMediaNumber,
    modalText,
    now,
  };
};

export default useAdList;
