import React, { useEffect, useState } from "react";
import { useDemo } from "./hooks/useDemo";
import styles from "./css/Demo.module.css"; // Import the CSS module
import { Line } from "react-chartjs-2";

const Demo: React.FC = () => {
  const {
    gender,
    handleGenderChange,
    age,
    handleAgeChange,
    category,
    handleCategoryChange,
    handleSearch,
    interestResults,
    localTrendResult,
    localPopulationResult,
    setAge,
    setGender,
    setCategory,
    populationRatio,
    setPopulationRatio,
    footTrafficResult,
    filteredTrend,
  } = useDemo();
  const [showResults, setShowResults] = useState(false);

  type Districts =
    | "종로구"
    | "중구"
    | "용산구"
    | "성동구"
    | "광진구"
    | "동대문구"
    | "중랑구"
    | "성북구"
    | "강북구"
    | "도봉구"
    | "노원구"
    | "은평구"
    | "서대문구"
    | "마포구"
    | "양천구"
    | "강서구"
    | "구로구"
    | "금천구"
    | "영등포구"
    | "동작구"
    | "관악구"
    | "서초구"
    | "강남구"
    | "송파구"
    | "강동구";

  interface InterestResult {
    localName: Districts;
    population: number;
  }

  const outDoorAd: Record<Districts, number> = {
    종로구: 14,
    중구: 40,
    용산구: 12,
    성동구: 5,
    광진구: 6,
    동대문구: 1,
    중랑구: 1,
    성북구: 4,
    강북구: 1,
    도봉구: 0,
    노원구: 4,
    은평구: 0,
    서대문구: 5,
    마포구: 18,
    양천구: 0,
    강서구: 4,
    구로구: 0,
    금천구: 0,
    영등포구: 10,
    동작구: 24,
    관악구: 5,
    서초구: 17,
    강남구: 70,
    송파구: 2,
    강동구: 1,
  };

  const handleSearchAndShowResults = () => {
    if (gender.length === 0 || age.length === 0 || category === "") {
      alert("성별, 연령대, 카테고리를 모두 선택해주세요.");
      return;
    }
    handleSearch();
    setShowResults(true);
  };

  const handleReset = () => {
    setAge([]);
    setGender([]);
    setCategory("");
    setShowResults(false);
    setPopulationRatio(0.5);
  };

  const handleCheckboxChange = (setter: any, values: any, value: any) => {
    if (values.includes(value)) {
      setter(values.filter((item: any) => item !== value));
    } else {
      setter([...values, value]);
    }
  };

  const handleSliderChange = (value: number) => {
    setPopulationRatio(value);
    handleSearchAndShowResults(); // 슬라이더 조절 시 검색 실행
  };

  useEffect(() => {
    if (gender.length > 0 && age.length > 0 && category) {
      handleSearchAndShowResults();
    }
  }, [gender, age, category, populationRatio]);

  const topInterests: InterestResult[] = showResults
    ? interestResults.sort((a, b) => b.population - a.population).slice(0, 3)
    : [];

  const topLocalTrends = showResults
    ? localTrendResult
        .sort((a, b) => b.interestMultiple - a.interestMultiple)
        .slice(0, 5)
    : [];

  const topLocalPopulation = showResults
    ? localPopulationResult
        .sort((a, b) => b.population - a.population)
        .slice(0, 5)
    : [];

  const colorPalette = [
    "blue",
    "green",
    "red",
    "orange",
    "purple",
    "cyan",
    "magenta",
    "yellow",
    "brown",
    "grey",
  ];

  const labels: string[] = [];
  const datasets: {
    label: string;
    data: number[];
    fill: boolean;
    borderColor: string;
    tension: number;
  }[] = [];

  const localNames = Array.from(
    new Set(filteredTrend.map((item) => item.localName))
  );

  localNames.forEach((localName, index) => {
    const data: number[] = [];
    filteredTrend.forEach((item) => {
      if (item.localName === localName) {
        data.push(parseFloat(item.totalPopulation));
        if (!labels.includes(item.yearMonth)) {
          labels.push(item.yearMonth);
        }
      }
    });

    datasets.push({
      label: localName,
      data: data,
      fill: false,
      borderColor: colorPalette[index % colorPalette.length],
      tension: 0.1,
    });
  });

  const data = {
    labels: labels,
    datasets: datasets,
  };

  const options: {
    responsive: boolean;
    maintainAspectRatio: boolean;
    scales: {
      [key: string]: {
        beginAtZero: boolean;
      };
    };
    plugins: {
      legend: {
        display: boolean;
        position: any;
      };
      title?: {
        display: boolean;
        text: string;
        font?: {
          size: number;
        };
      };
    };
  } = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
      },
      x: {
        beginAtZero: false,
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
      title: {
        display: true,
        text: "월별 유동인구 예측 그래프",
        font: {
          size: 20,
        },
      },
    },
  };
  return (
    <div className={styles.container}>
      <h1>데모 프로그램</h1>
      <div className={styles["select-container"]}>
        <div className={styles.checkboxGroup}>
          <label>성별 선택</label>
          <label>
            <input
              type="checkbox"
              checked={gender.includes("male")}
              onChange={() => handleCheckboxChange(setGender, gender, "male")}
            />
            남자
          </label>
          <label>
            <input
              type="checkbox"
              checked={gender.includes("female")}
              onChange={() => handleCheckboxChange(setGender, gender, "female")}
            />
            여자
          </label>
        </div>

        <div className={styles.checkboxGroup}>
          <label>연령대 선택</label>
          {["0", "10", "20", "30", "40", "50", "60"].map((ageValue) => (
            <label key={ageValue}>
              <input
                type="checkbox"
                checked={age.includes(ageValue)}
                onChange={() => handleCheckboxChange(setAge, age, ageValue)}
              />
              {ageValue}대
            </label>
          ))}
        </div>

        <div className={styles.checkboxGroup}>
          <label>카테고리 선택</label>
          <select
            id="category"
            value={category}
            onChange={handleCategoryChange}
          >
            <option value="">업종 카테고리</option>
            <option value="피부과">피부과</option>
            <option value="성형외과">성형외과</option>
            <option value="건강기구">건강기구</option>
            <option value="공연장, 웨딩홀 등 장소 대관">
              공연장, 웨딩홀 등 장소 대관
            </option>
            <option value="가수, 음반 등 문화콘텐츠">
              가수, 음반 등 문화콘텐츠
            </option>
          </select>

          <div className={styles.sliderContainer}>
            <label>
              인구수 비율 (유동인구 기준): {populationRatio.toFixed(2)}
            </label>
            <input
              type="range"
              min="0"
              max="1"
              step="0.01"
              value={populationRatio}
              onChange={(e) => handleSliderChange(parseFloat(e.target.value))}
              onMouseUp={(e) =>
                handleSliderChange(parseFloat(e.currentTarget.value))
              }
              onTouchEnd={(e) =>
                handleSliderChange(parseFloat(e.currentTarget.value))
              }
            />
          </div>
        </div>
      </div>

      <button className={styles["demo-button"]} onClick={handleReset}>
        초기화
      </button>

      {showResults && (
        <div className={styles.results}>
          <h1>오프라인광고 상위 3개 지역추천</h1>
          <ol className={styles["horizontal-list"]}>
            {topInterests.map((interest, index) => (
              <li key={index}>
                {index + 1}. {interest.localName}{" "}
                <span>({outDoorAd[interest.localName]}개)</span>
              </li>
            ))}
          </ol>
          <span className={styles["adNotice"]}>
            (괄호안의 수는 해당지역의 옥외광고 수)
          </span>

          <div className={styles["horizontal-sections"]}>
            <div className={styles["horizontal-section"]}>
              <h2>선택한 카테고리에 따른 네이버 관심도 상위 5개 지역</h2>
              <ol>
                {topLocalTrends.map((trend, index) => (
                  <li key={index}>
                    {trend.siGunGu} ({(trend.interestMultiple * 100).toFixed(2)}
                    %)
                  </li>
                ))}
              </ol>
            </div>

            <div className={styles["horizontal-section"]}>
              <h2>성별, 연령대에 따른 상주인구수 상위 5개 지역</h2>
              <ol>
                {topLocalPopulation.map((interest, index) => (
                  <li key={index}>
                    {interest.localName} (
                    {(interest.totalPopulation / 10000).toFixed(0)}만명)
                  </li>
                ))}
              </ol>
            </div>

            <div className={styles["horizontal-section"]}>
              <h2>성별, 연령대에 따른 유동인구수 상위 5개 지역</h2>
              <ol>
                {footTrafficResult.map((interest, index) => (
                  <li key={index}>
                    {interest.localName} (
                    {(interest.totalPopulation / 10000).toFixed(0)}만명)
                  </li>
                ))}
              </ol>
            </div>
            <div className={styles["horizontal-chart-section"]}>
              <div className={styles["chart-container"]}>
                <Line data={data} options={options} />
              </div>
            </div>
          </div>
          {/* <div className={styles["chart-container"]}>
          <Line data={data} options={options} />
        </div> */}
        </div>
      )}
    </div>
  );
};

export default Demo;
