import useDalgoTMonthlyDashboard from "./hooks/useDalgoTMonthlyDashboard";
import "./css/useDalgoTMonthlyDashboard.scss";
import { Link } from "react-router-dom";

const DalgoTMonthlyDashboard: any = () => {
  const h = useDalgoTMonthlyDashboard();

  return (
    <div id="dalgoTMonthlydashboard">
      <div>
        <h1 style={{ margin: "0px" }}>달고 T - 월간 데이터 관제</h1>

        <select
          id="media_select"
          onChange={(e) => {
            h.setMedia(Number(e.target.value));
          }}>
          {h.mediaList.map((item: any, index: number) => {
            return (
              <option value={item.authNumber} key={index}>
                {item.authNumber}) {item.name}
              </option>
            );
          })}
        </select>
        <button onClick={() => h.excelDownload()}>월간 데이터 다운로드</button>
      </div>
      <div>
        <div>
          <div>
            <div>
              <button
                onClick={() => {
                  h.changeMonth(-1);
                }}>
                {"<"}
              </button>
              <div>
                <div>
                  {h.year}년 {h.month}월
                </div>
              </div>
              <button
                onClick={() => {
                  h.changeMonth(1);
                }}>
                {">"}
              </button>
            </div>
            <button onClick={() => h.today()}>오늘</button>
          </div>

          <div>
            <div id="calendar">
              {["일", "월", "화", "수", "목", "금", "토"].map((item, idx) => {
                return <span key={idx}>{item}</span>;
              })}
              {h.dataArray.map((item, idx) => {
                let color = "";
                if (!item.date) {
                  color = "no_date";
                } else if (!item.info) {
                  color = "no_csv";
                } else if (!item.info?.data?.length) {
                  color = "no_data";
                }
                let infoData;
                if (item.info?.data) {
                  infoData = h.createInfo(item.info.data);
                }

                return (
                  <div
                    key={idx}
                    className={color}
                    onClick={() => {
                      h.setDate(item.date);
                    }}>
                    <span>{item.date}</span>
                    {infoData ? (
                      <>
                        <span>{infoData[3]}</span>
                        <span>{infoData[1]}km</span>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                );
              })}
            </div>
            <div id="info">
              <div>{h.dateText}</div>
              {typeof h.info !== "string" ? (
                <div>
                  <div>가동시간 : {h.info[0]}</div>
                  <div>일간 이동거리 : {h.info[1]}km</div>
                  <div>일간 평균 주행 속도 : {h.info[2]}km/h</div>
                  <div>운행시간 : {h.info[3]}</div>
                  <div>휴식시간 : {h.info[4]}</div>
                </div>
              ) : (
                <div>{h.info}</div>
              )}
              <div>
                <Link
                  to={`/dalgoT/daily?media=${h.media}&date=${h.year}-${
                    h.month < 10 ? "0" + h.month : h.month
                  }-${h.date < 10 ? "0" + h.date : h.date}`}>
                  <button>일간 관제 바로가기</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DalgoTMonthlyDashboard;
