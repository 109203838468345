import { useEffect, useState } from "react";
import axios from "axios";

interface hookMember {
  year: number;
  month: number;
  date: number;
  setDate: (n: number) => void;
  dataArray: any[];
  changeMonth: (n: number) => void;
  media: number;
  setMedia: (media: number) => void;
  mediaList: any[];
  today: () => void;
  info: any;
  excelDownload: () => void;
  dateText: string;
  createInfo: (data: any) => any;
}

const useDalgoTDashboard = (): hookMember => {
  const [year, setYear] = useState<number>(-1);
  const [month, setMonth] = useState<number>(-1);
  const [date, setDate] = useState<number>(-1);
  const [dateArray, setDateArray] = useState<any[]>([]);
  const [dataArray, setDataArray] = useState<any[]>([]);
  const [mediaList, setMediaList] = useState<any[]>([]);
  const [media, setMedia] = useState<number>(0);
  const [info, setInfo] = useState<any>("");
  const [dateText, setDateText] = useState<string>("");

  // 오늘 날짜로 설정
  useEffect(() => {
    if (year < 0 && month < 0) {
      today();
    }
    fetchMediaList();
  }, []);

  // 달 변경
  useEffect(() => {
    if (year === -1 || month === -1) return;
    let array = [];
    for (let i = 0; i < new Date(year, month - 1, 1).getDay(); i++) {
      array.push("");
    }
    for (let i = 0; i < new Date(year, month, 0).getDate(); i++) {
      array.push(i + 1);
    }
    let a = 7 - (array.length % 7) === 7 ? 0 : 7 - (array.length % 7);
    for (let i = 0; i < a; i++) {
      array.push("");
    }
    setDateArray(array);
  }, [year, month]);

  // 해당 달의 데이터 불러오기
  useEffect(() => {
    fetchFileList(dateArray);
  }, [media, dateArray]);

  useEffect(() => {
    dataArray.map((item: any, idx: number) => {
      if (item.date === date) {
        clickDate(idx);
      }
    });
  }, [dataArray, date]);

  const fetchMediaList = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}/media/byBusiness?service=DalgoT`
      );
      const jsonData = await response.json();

      if (jsonData.status == 200) {
        setMedia(jsonData.data[0].authNumber);
        setMediaList(jsonData.data);
      } else {
        setMedia(0);
        setMediaList([]);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchFileList = async (d: any[]) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}/dashboard/dalgoT/monthly?media=${media}&year=${year}&month=${month}`
      );
      const jsonData = await response.json();

      if (jsonData.status == 200) {
        let a = d.map((item) => {
          if (!item) return { date: "", info: {} };
          return {
            date: item,
            info: jsonData.data[item - 1],
          };
        });
        setDataArray(a);
      } else if (jsonData.status == 400) {
        let a = d.map((item) => {
          if (!item) return { date: "", info: {} };
          return {
            date: item,
            info: null,
          };
        });
        setDataArray(a);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const excelDownload = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/dashboard/dalgoT/excel?media=${media}&year=${year}&month=${month}`,
        {},
        {
          responseType: "blob",
        }
      );

      // 파일 다운로드를 위한 Blob 생성
      const blob = new Blob([response.data], { type: "text/xlsx" });
      const url = window.URL.createObjectURL(blob);

      // 다운로드 링크 생성 및 클릭
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${media}매체_${year}년${month}월_월간 운행기록.xlsx`
      );
      document.body.appendChild(link);
      link.click();

      // 생성된 URL 해제
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error creating xlsx:", error);
    }
  };

  function changeMonth(n: number) {
    setMonth(month + n);
    if (month === 1 && n === -1) {
      setYear(year - 1);
      setMonth(12);
    }
    if (month === 12 && n === 1) {
      setYear(year + 1);
      setMonth(1);
    }
    setDate(1);
  }

  function clickDate(n: number) {
    let data = dataArray[n];

    if (data.date) {
      setDate(data.date);
      setDateText(`${year}년 ${month}월 ${data.date}일`);
      if (data.info) {
        if (!data.info.gps || !data.info.data.length) {
          setInfo("csv파일이 비어있습니다.");
          return;
        }
        const infoData = createInfo(data.info.data);
        setInfo(infoData);
      } else {
        setInfo("데이터가 없습니다.");
      }
    }
  }

  function createInfo(data: any) {
    if (data.length == 0) return;
    let time_text = data[0].start + " ~ " + data[data.length - 1].end;

    let distance = 0;
    let full_time = diffTime(data[0].start, data[data.length - 1].end);
    let running_time = 0;

    data.map((item: any) => {
      distance += item.distance;
      running_time += diffTime(item.start, item.end);
    });

    let rest_time = full_time - running_time;

    return [
      time_text,
      distance.toFixed(2),
      ((60 * distance) / running_time).toFixed(2),
      TimeText(running_time),
      TimeText(rest_time),
    ];
  }

  function diffTime(start: string, end: string) {
    let start_bit = start.split(":");
    let end_bit = end.split(":");

    let start_time = Number(start_bit[0]) * 60 + Number(start_bit[1]);
    let end_time = Number(end_bit[0]) * 60 + Number(end_bit[1]);

    return end_time - start_time;
  }

  function TimeText(n: number) {
    let m = n % 60;
    let h = (n - m) / 60;
    return `${h}시간 ${m}분`;
  }

  function today() {
    const now = new Date();
    setYear(now.getFullYear());
    setMonth(now.getMonth() + 1);
    setDate(now.getDate());

    setDateText(
      `${now.getFullYear()}년 ${now.getMonth() + 1}월 ${now.getDate()}일`
    );
  }

  return {
    year,
    month,
    date,
    setDate,
    dataArray,
    changeMonth,
    media,
    setMedia,
    mediaList,
    today,
    info,
    excelDownload,
    dateText,
    createInfo,
  };
};

export default useDalgoTDashboard;
